import React, { useState, useEffect, useRef } from "react";
import '../sections/BannerSection.css';
import { Carousel } from "react-bootstrap";
import { Divider } from "@chakra-ui/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import video1 from './videos/WEB_BLM.webm';

const BannerSection = () => {
    const [currentVideo, setCurrentVideo] = useState(0);
    const videos = [
        video1
    ];
    const videoRef = useRef(null);

    const handleVideoEnd = () => {
        setCurrentVideo((currentVideo + 1) % videos.length);
    };

    useEffect(() => {
        const videoElement = videoRef.current;

        const restartPlayback = () => {
            setCurrentVideo((currentVideo + 1) % videos.length);
        };

        videoElement.addEventListener('ended', restartPlayback);

        return () => {
            videoElement.removeEventListener('ended', restartPlayback);
        };
    }, [currentVideo, videos.length]);

    return (
        <>
            <div className='hero-container'>
                <Carousel className="seccion-video" >
                    <Carousel.Item>
                        <video ref={videoRef} className="video-home" src={videos[currentVideo]} onEnded={handleVideoEnd} autoPlay loop muted />
                    </Carousel.Item>
                </Carousel>
            </div>
            <Divider className="divisor-desc" />

        </>
    );
}

export default BannerSection;
import React from "react";
import '../Commerces/css commerces/comercio.css';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../../Footer";

function Comercio_anzoategui() {

    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución del Anzoategui</h2>
                            <br /><br /><br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">INVERSIONES RUEDIGOMAS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CTRA SALIDA A PARIAGUAN LOCAL NRO 1 Y 2 SECTOR LA BOTELLA, LA CHARNECA EL TIGRE ANZOATEGUI ZONA POSTAL 6050.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedigomas/" target="_blank">@ruedigomas</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584162838037" target="_blank"> +58 416-2838037</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15767.421768817345!2d-64.27579826359501!3d8.8930291592016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcd8174d061124b%3A0x53acb48e39013042!2sInversiones%20Ruedigomas%2C%20C.A.!5e0!3m2!1sen!2sve!4v1695246043032!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">LA FERIA DEL CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV INTERCOMUNAL JORGE RODRIGUEZ EDIF CAM.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/laferiadelcaucho/" target="_blank">@laferiadelcaucho</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148245486" target="_blank"> +58 414-8245486</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6604.9741271952325!2d-64.68513584322056!3d10.152902877022093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d737335714169%3A0xb2b9cea5d8fef3e2!2sLA%20FERIA%20DEL%20CAUCHO%20BARCELONA%2C%20C.A.!5e0!3m2!1sen!2sve!4v1695246340067!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">SUMINISTRO Y SERVICIOS SUSELP, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV MERIDA CASA NRO 4-73-C SECTOR PUEBLO NUEVO ANACO ANZOATEGUI, ZONA POSTAL 6003.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/suselpca/" target="_blank">@suselpca</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584129472692" target="_blank"> +58 412-9472692</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5566.307348057341!2d-64.47456212415419!3d9.423711338327298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dd2a5c3697bcb2d%3A0x83763a852782bcdf!2sSUMINISTROS%20Y%20SERVICIOS%20SUSELP%2C%20C.A.!5e0!3m2!1sen!2sve!4v1695246465231!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                            <br /><br /><br /><br /><br />
                        </div>
                    </center>
                </div >
            </body >
            <Footer />
        </>
    );
}

export default Comercio_anzoategui;
import React from "react";
import '../sections/SplitSection.css';
import Flickity from "flickity";

function SplitSection() {
    var elem = document.querySelector(".carousel-container");
    var flkty = new Flickity(elem, {
        // options
        cellAlign: "right",
        contain: true,
        initialIndex: 2,
        pageDots: false,
        wrapAround: true,
        autoPlay: 5000,
        draggable: true,
        prevNextButtons: false,
        pauseAutoPlayOnHover: false
    });


    return (
        <>

            <div class="carousel-container">
                <div class="carousel-slide" id="hero-split-1">

                    <div className="carousel-slide-copy">
                        <div className="button-container">
                            <a href="https://moura.com.ve/" target="_blank" className="button">Haz click aquí</a>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default SplitSection;
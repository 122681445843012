import React from "react";
import Header_history from "./enterprise/Header_history";
import History from "./enterprise/History";
import Header_we from "./enterprise/Header_we";
import We from "./enterprise/We";
import Footer from '../Footer';

function Aboutus() {

    return (
        <>
            <Header_history />
            <History />
            <Header_we />
            <We />
            <Footer />
        </>
    );
}

export default Aboutus;
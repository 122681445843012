import React from "react";
import '../pages/Mark.css';
import Footer from '../Footer';


function Mark() {

    return (
        <>
            <div className="moura">
                <div id="parallax-world-of-ugg">

                    <section>
                        <div class="parallax-one">
                        </div>
                    </section>

                    <section className="seccion-video" style={{ padding: "0" }}>
                        <div className="contenedor-video">
                            <video className="video-1" autoPlay loop muted id="player">
                                <source src="./videos/publi.webm" />
                            </video>
                        </div>
                    </section>



                    <section>
                        <div class="parallax-two">
                        </div>
                    </section>

                    <section className="seccion-video" style={{ padding: "0" }}>
                        <div className="contenedor-video">
                            <video className="video-1" autoPlay loop muted id="player">
                                <source src="./videos/publi.webm" />
                            </video>
                        </div>
                    </section>

                    <section>
                        <div class="parallax-three">
                        </div>
                    </section>


                </div>
            </div>

            <Footer />
        </>
    );
};

export default Mark;
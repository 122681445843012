import React from "react";
import '../enterprise/History.css';

function History() {

    return (
        <>
            <section id="timeline">
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/historia/historia-1.webp)', backgroundPositionX: "45%" }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Orgullosos</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "19px" }}>Fundada en agosto del 2019 después de incursionar en el mercado de llantas, tomando la experiencia de más de 30 años en la distribución nacional de productos automotrices a nivel nacional, se establece un nuevo camino dentro de la distribución de acumuladores para vehículos.
                        </p>
                    </div>
                </div>
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/historia/historia-2.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Baterías</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "20px" }}>Tomando una de las marcas más importantes del mercado mundial, MOURA se une a Baterias La Mundial para una alianza extraordinaria a niveles de distribución nacional con el personal más calificado y preparado para la incursión en este mercado.</p>
                    </div>
                </div>
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/historia/historia-3.webp)', backgroundPositionX: "65%" }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>La</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "19px" }}>Uniendo los esfuerzos se posiciona la marca MOURA en cada rincón de Venezuela con más de 40 centros de servicio y garantía, con mas de 700 puntos de venta, para ofrecer la mejor de batería de Latinoamérica, cada 5 de 10 automóviles de Latinoamérica utilizan MOURA.</p>
                    </div>
                </div>
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/historia/historia-4.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Mundial</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "19px" }}>Este crecimiento nos ha permitido expandir nuestra experiencia y formar parte de un Holding de empresas con reconocimiento Internacional como Grupo La Mundial, que esta comprendido por Ruedas La Mundial, Cauchos La Mundial, Lubricantes La Mundial y Equipamiento La Mundial.
                        </p>
                    </div>
                </div>
            </section>

        </>
    );
}

export default History;
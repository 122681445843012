import React from "react";
import '../Commerces/css commerces/comercio.css';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../../Footer";

function Comercio_carabobo() {

    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución del Carabobo</h2>
                            <br /><br /><br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">INVERSIONES GUAIMARATA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV PRINCIPAL PROLONGACION TRANSVERSAL I VIA PARAPARAL LOCAL NRO 870-212 SECTOR FELIX RIOS LOS GUAYOS CARABOBO .<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584166481663" target="_blank"> +58 416-6481663</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5553.56706751665!2d-67.92991681428224!3d10.183503182035446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8069bc190685ff%3A0x11d29e98280d7785!2sInversiones%20Guaimarata%2C%20c.a!5e0!3m2!1sen!2sve!4v1695244889805!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">GUSTAVO FRIDOLINO PACHECO VALLES, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE ANZOATEGUI CASA NRO 9-21 SECTOR CASCO CENTRAL PUERTO CABELLO CARABOBO ZONA POSTAL 2050.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584127593038" target="_blank"> +58 412-7593038</a><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">BLACK WHEELS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> URB. AGUA BLANCA A 50MTS DE LA AV. ANDRES ELOY BLANCO  NRO. 127 EDIFICIO FRAMECA.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/blackwheels_ve/" target="_blank">@blackwheels_ve</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584244119759" target="_blank"> +58 424-4119759</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6603.954868200294!2d-68.01622970976969!3d10.202157624344482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e805d787ea4e815%3A0xf743f608a76e42e3!2sBlack%20wheels!5e0!3m2!1sen!2sve!4v1695245113024!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                            <br /><br /><br /><br /><br />
                        </div>
                    </center>
                </div >
            </body >
            <Footer />
        </>
    );
}

export default Comercio_carabobo;
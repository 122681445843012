import React from "react";
import '../enterprise/We.css';

function We() {

    return (
        <>

            <section id="timeline">
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/valores.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Valores</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "18px" }}>
                            Responsabilidad. Calidad de trabajo, Honestidad e Integridad. Esta es la raíz de nuestro equipo de trabajo, de la mano con la responsabilidad social y pasión por servir que nos representa.
                            Estos valores nos han permitido marcar el éxito en la industria de comercialización de acumuladores eléctricos en Venezuela y ser referencia en Latinoamérica y el mundo.
                        </p>
                    </div>
                </div>
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/mision.webp)', backgroundPositionX: "30%" }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Misión</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "22px" }}>Comercializar acumuladores eléctricos de alta calidad para todo tipo de vehículo automotor, comprometidos con productos de calidad y asistencia técnica especializada.</p>
                    </div>
                </div>
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/vision.webp)', backgroundPositionX: "70%" }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Visión</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "22px" }}>Ser conocidos como organización líder y mejor opción de comercialización de acumuladores en la industria automotriz de Venezuela y América Latina.</p>
                    </div>
                </div>
                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/proposito.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Propósito</p>
                    </div>
                    <div className="tl-content">
                        <p style={{ fontSize: "22px" }}>Comercializar acumuladores de alta calidad mundial, atado al valor a nuestros clientes y satisfacción al consumidor final.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default We;
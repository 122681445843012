import React from "react";
import '../enterprise/Header_history.css';

function Header_history() {

    return (
        <>
            <h1 className="cabecera-principal">
                HISTORIA
            </h1>
        </>
    );
}

export default Header_history;
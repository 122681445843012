import React from "react";
import '../enterprise/Header_we.css';

function Header_we() {

    return (
        <>
            <h1 className="cabecera-nosotros">
                NOSOTROS
            </h1>
        </>
    );
}

export default Header_we;
import React from "react";
import '../Commerces/css commerces/comercio.css';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../../Footer";

function Comercio_bolivar() {

    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución del Bolívar</h2>
                            <br /><br /><br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">DISTRIBUIDORA DICE, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE RIO CARIBE EDIF RIO CA PISO PB LOCAL S/N URB ORINOCO PUERTO ORDAZ BOLIVAR.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupodice/" target="_blank">@grupodice</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584128791303" target="_blank"> +58 412-8791303</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7895.780437154062!2d-62.710450126709375!3d8.31370730737409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbfa471a579d01%3A0xe10adb99a6bea714!2sDistribuidora%20Dice%2C%20C.A.!5e0!3m2!1sen!2sve!4v1695245292863!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">SERVI CAUCHOS NG, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV AVENIDA ANTONIO DE BERRIO LOCAL S/N SECTOR EL ROBLE UNIDAD DE DESARROLLO UD-147 CIUDAD GUAYANA .<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servicauchosng/" target="_blank">@servicauchosng</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584128602880" target="_blank"> +58 412-8602880</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5582.61537807096!2d-62.676191456199476!3d8.351860105767784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf06f59cfa1b9%3A0x6fdaa9e97af2ee21!2sServi%20Cauchos%20NG%2C%20C.A.%20(antiguo%20local%20Mega-Ruedas)!5e0!3m2!1sen!2sve!4v1695245435720!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">AUTO REPUESTOS PUNTO AMARILLO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV REPUBLICA CC LOS LALOS NIVEL P/B LOCAL 2 SECTOR AV. REPUBLICA PARROQUIA CATEDRAL MUNICIPIO HERES CIUDAD BOLIVAR.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/repuestospuntoamarillo/" target="_blank">@repuestospuntoamarillo</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148521826" target="_blank"> +58 414-8521826</a><br />
                                        <br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15799.09918102901!2d-63.55828796382165!3d8.124396713619756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce87085343b00d%3A0x616e5a3368208d9f!2sAuto%20Repuestos%20Punto%20Amarillo%2C%20C.A.!5e0!3m2!1sen!2sve!4v1695245643648!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                            <br /><br /><br /><br /><br />
                        </div>
                    </center>
                </div >
            </body >
            <Footer />
        </>
    );
}

export default Comercio_bolivar;
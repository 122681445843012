import React from "react";
import '../sections/DescriptionSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusAlt, faHeadset, faClock, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { Divider } from "@chakra-ui/react";
import 'bootstrap/dist/css/bootstrap.min.css';

function DescriptionSection() {

    return (
        <>
            <Divider className="divisor-desc" />
            <div className="description">
                <div className="row">
                    <div className="col-lg-3 icono-deatil" id="icon-first">
                        <center>
                            <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '50px', color: "#000" }} />
                            <p className="title" style={{ color: "#000" }}>30 años de experiencia</p>
                        </center>
                    </div>
                    <div className="col-lg-3 icono-deatil" id="icon-second">
                        <center>
                            <FontAwesomeIcon icon={faClock} style={{ fontSize: '50px', color: "#ececec" }} />
                            <p className="title" style={{ color: "#fff" }}>Despacho 72 horas</p>
                        </center>
                    </div>
                    <div className="col-lg-3 icono-deatil" id="icon-third">
                        <center>
                            <FontAwesomeIcon icon={faBusAlt} style={{ fontSize: '50px', color: "#000" }} />
                            <p className="title" style={{ color: "#000" }}>Red de distribución nacional</p>
                        </center>
                    </div>
                    <div className="col-lg-3 icono-deatil" id="icon-fourty">
                        <center>
                            <FontAwesomeIcon icon={faHeadset} style={{ fontSize: '50px', color: "#ececec" }} />
                            <p className="title" style={{ color: "#fff" }}>Soporte técnico especializado</p>
                        </center>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DescriptionSection;